.login {
  position: relative;
  background: #0066cc;
  overflow: auto;
  border: 1px solid #707070;
  /* height: 99.7vh; */
}

.okta-form-title {
  margin-bottom: 0px !important;
  display: none;
}
#okta-sign-in .custom-checkbox,
#okta-sign-in .custom-radio {
  position: relative;
  clear: both;
  margin-left: 120px;
}
#okta-sign-in .o-form .o-form-label,
#okta-sign-in .o-form input,
#okta-sign-in .o-form label,
#okta-sign-in .o-form textarea {
  font-size: 15px;
  font-family: "OpenSans Regular";
}

.okta-form-input-field {
  border-radius: 0px !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  /* input {
    width: 90% !important;
  } */
}

#okta-sign-in .focused-input {
  box-shadow: none !important;
}

#okta-sign-in .auth-header {
  border-bottom: 0 !important;
  display: none;
}

#okta-sign-in * {
  font-family: "Open Sans", sans-serif;
}

.okta-form-label,
.o-form-input-name-remember,
.o-form-has-errors {
  text-align: left;
}

.o-form-fieldset-container {
  text-align: center;
}

.button.button-primary {
  border-radius: 100px !important;
  height: 70px;
  width: 120px !important;
  text-transform: uppercase;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  font-weight: bold !important;
  text-align: center;
  border: 0.5px solid transparent !important;
  padding: 5px 0.75rem !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  height: auto !important;
  font-family: "Open Sans", sans-serif;
  background: #0066cc;
}

.okta-widget {
  font-family: "Open Sans", sans-serif;
}

#okta-sign-in.auth-container h2,
#okta-sign-in.auth-container h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #3d515a;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 5px;
}

#okta-sign-in.auth-container.main-container {
  background-color: #fff;
  border-color: #ddd #ddd #d8d8d8;
  box-shadow: 0 2px 0 rgb(255, 255, 255);
  border-radius: 10px !important;
  margin-top: 15px;
}

#okta-sign-in.auth-container .okta-form-label {
  font-family: "Open Sans,sans-serif";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #7d898d;
}

#okta-sign-in {
  height: auto;
  margin: 0px;
  border-radius: 0px;
  border-style: none;
}

#okta-sign-in .auth-footer {
  display: none;
}

#okta-sign-in.no-beacon .auth-content {
  padding-top: 0px;
}

#okta-sign-in .auth-content {
  padding: 60px 42px 11px;
  transition: padding-top 0.4s;
  max-height: 420px;
  overflow-y: auto;

}

#okta-sign-in .o-form-button-bar {
  background: transparent;
  border: none;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}

#okta-sign-in.auth-container.main-container .margin-btm-30 {
  margin-bottom: 20px;
}
