.assurant-logo {
    padding-top: 20px;
    display: block;
    margin: 0 auto;
    width: 260px;
    height: 50px;
  }
  .remember-me {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 26px;
    font-family: "OpenSans Regular";
    color: #3d515a;
  }
  .remember-me-sign-in {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 26px;
    font-family: "OpenSans Regular";
    color: #3d515a;
  }
  
  .sign-in {
    background: #0066cc;
    opacity: 0.6;
    border: 0.5px solid #0047ad;
    border-radius: 20px;
  }
  
  .title-name {
    text-align: center;
    font-family: "OpenSans Bold";
    font-size: 24px;
    line-height: 14px;
    color: #3d515a;
  }
  .MainDiv {
    padding: 0 24px;
    display: block;
    justify-content: center;
  }
  .username-icon {
    display: flex;
    margin: 0 0 35px 0;
  }
  .password-icon {
    display: flex;
  }
  .signin-password {
    margin-top: 20px;
    padding-right: 10px;
  }
  .dropdown-helpWith {
    margin: 46.5px 0 38px 0;
  }