body {
  margin: 0;
}

.bottom-footer {
  font-family: "OpenSans Regular";
  font-size: 16px;
  line-height: 28px;
  color: white;
  margin-left: 30px;
}

.login-main,
.server-error-main {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../assets/images/backgroundImage.jpg");
  overflow: hidden;
  overflow-block: unset;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-screen {
  background-color: white;
  border-radius: 20px;
  z-index: 1010;
}

.forgot-passowrd-screen {
  min-width: 500px;
  min-height: 420px;
  z-index: 1010;
  background-color: white;
  border-radius: 20px;
}

.support-screen {
  min-width: 450px;
  z-index: 1010;
  background-color: white;
  border-radius: 20px;
}

.login-forgot {
  background-color: white;
  border-radius: 20px;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.title-name {
  text-align: center;
  font-family: "OpenSans Bold";
  font-size: 24px;
  line-height: 30px;
  color: #3d515a;
}

.client-title {
  text-align: center;
  font-family: "OpenSans SemiBold";
  line-height: 33px;
  font-size: 12px;
  color: #3d515a;
}

.tab-name {
  text-transform: capitalize !important;
}

.remember-me {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 26px;
  font-family: "OpenSans Regular";
  color: #3d515a;
}

.remember-me-sign-in {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 26px;
  font-family: "OpenSans Regular";
  color: #3d515a;
}

.sign-in {
  background: #0066cc;
  opacity: 0.6;
  border: 0.5px solid #0047ad;
  border-radius: 20px;
}

.lower-border {
  width: 45%;
  border-bottom: 1px solid gray;
}

@media screen and (max-width: 1366px) {
  .login-screen .assurant-logo {
    padding-top: 20px;
    display: block;
    margin: 0 auto;
    height: 37px;
  }
}

.username-icon {
  display: flex;
  margin: 0 0 35px 0;
}

.password-icon {
  display: flex;
}

.forResetPass-icon {
  margin-bottom: 15px;
}

.signin-password {
  margin-top: 20px;
  padding-right: 10px;
}

.t-c {
  height: 30px;
  font-family: "OpenSans Regular";
  font-size: 14px;
  line-height: 26px;
  color: #2972c8;
}

.Or {
  align-items: center;
  display: flex;
  height: 26px;
  background: #ffffff;
  color: #596981;
  font-size: 14px;
  font-family: "OpenSans Regular";
  margin-bottom: 12.57px;
}

.lower-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.password {
  font-family: "OpenSans Bold";
  line-height: 33px;
  font-size: 33px;
  text-align: center;
  color: #13223c;
}

.back-signin {
  margin-top: 15px;
  font-family: "OpenSans Regular";
  font-size: 14px;
  line-height: 26px;
  color: #2972c8;
  text-align: center;
}

.back-to-signin {
  cursor: pointer;
}

.pwd-ul {
  margin-top: 5px;
}

.dropdown-helpWith {
  margin: 46.5px 0 38px 0;
}

.MainDiv {
  padding: 30px 24px 0 24px;
  display: block;
  justify-content: center;
}

.pass-req {
  font-family: "OpenSans Regular";
  font-size: 16px;
  line-height: 24px;
  color: #3d515a;
}

.ul {
  margin: 0;
}

.sign-up {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 26px;
  font-family: "OpenSans Regular";
  color: #3d515a;
}

.activate-screen {
  background-color: white;
  border-radius: 20px;
  width: 450px;
  height: auto;
}

.title-password {
  text-align: center;
  font-family: "OpenSans Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #3d515a;
}

.password-error {
  display: flex;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  color: #68758b;
  line-height: 18px;
}

.reset-error {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.MainDiv .css-1pxa9xg-MuiAlert-message {
  padding: 8px 0;
  min-width: 0;
  overflow: hidden;
}

.mfa-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
}

.server-error-screen {
  font-family: "OpenSans Regular" ;
  color: #13223C;
  background-color: white;
  border-radius: 20px;
  z-index: 1010;
  padding: 20px 50px;
}