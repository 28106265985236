@font-face {
  font-family: "OpenSans Bold";
  src: url("./Open_Sans/static/OpenSans/OpenSans-Bold.ttf");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "OpenSans BoldIt";
  src: url("./Open_Sans/static/OpenSans/OpenSans-BoldItalic.ttf");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "OpenSans Regular";
  src: url("./Open_Sans/static/OpenSans/OpenSans-Regular.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "OpenSans Italic";
  src: url("./Open_Sans/static/OpenSans/OpenSans-Italic.ttf");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "OpenSans Light";
  src: url("./Open_Sans/static/OpenSans/OpenSans-Light.ttf");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "OpenSans SemiBold";
  src: url("./Open_Sans/static/OpenSans/OpenSans-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
  font-display: block;
}
