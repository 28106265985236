#loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
  #loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 120px;
    height: 120px;
    margin: -75px 0 0 -75px;
    border: 5px solid dodgerblue;
    z-index: 1500;
    border: 5px solid transparent;
    border-bottom-color: royalblue;
    border-top-color: royalblue;
    -webkit-animation: spin 1.5s linear;
    animation: spin 1.5s linear infinite;
    border-radius: 50%;
    -webkit-animation: spin 2s linear;
    animation: spin 2s linear infinite;
  }
  #loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border: 5px solid green;
    border: 5px solid transparent;
    border-top-color: #76ba1b;
    border-radius: 50%;
    -webkit-animation: spin 1.5s linear;
    animation: spin 1.5s linear infinite;
    -webkit-animation: spin 3s linear;
    animation: spin 3s linear infinite;
  }
  #loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border: 5px solid darkorange;
    border: 5px solid transparent;
    border-top-color: darkorange;
    border-radius: 50%;
    -webkit-animation: spin 0.5s linear;
    animation: spin 0.5s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  